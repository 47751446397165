import styled from 'styled-components';
import { Col } from 'react-bootstrap';
import React from 'react';
import PropTypes from 'prop-types';

const StyledCenteredCol = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  flex-direction: column;
`;

const FullCol = ({ children }) => (
  <StyledCenteredCol
    xs={12}
    md={{
      span: 10,
      offset: 1,
    }}
    lg={{
      span: 8,
      offset: 2,
    }}
    xl={{
      span: 6,
      offset: 3,
    }}
  >
    { children }
  </StyledCenteredCol>
);

FullCol.propTypes = {
  children: PropTypes.node.isRequired,
};

const HalfCol = ({ children }) => (
  <>
    <StyledCenteredCol
      xs={12}
      md={6}
      lg={{
        span: 4,
        offset: 1,
      }}
    >
      { children }
    </StyledCenteredCol>
  </>
);

HalfCol.propTypes = {
  children: PropTypes.node.isRequired,
};

export { FullCol, HalfCol };
