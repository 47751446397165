/* eslint-disable global-require */
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Parallax } from 'react-parallax';
import Slide from 'react-reveal/Slide';
import styled from 'styled-components';
import SizedSection from '../styled-components/Section/Section';
import bg1 from '../../assets/images/bg1/xs.jpeg';
import bg4xs from '../../assets/images/bg4/bg4xs.jpg';
import bg4xl from '../../assets/images/bg4/bg4xl.jpg';
import bg5xs from '../../assets/images/bg5/bg5xs.jpg';
import bg5xl from '../../assets/images/bg5/bg5xl.jpg';
import { FullCol, HalfCol } from '../styled-components/Cols/Cols';

const StyledParagraph = styled.p`
  padding: 20px;
  text-align: justify;
  padding-bottom: ${props => (props.noPaddingBottom ? 0 : 20)}px;
  margin-bottom: ${props => (props.noPaddingBottom ? 0 : 20)}px;
  padding-top: ${props => (props.noPaddingTop ? 0 : 20)}px;
`;

const StyledSocialImage = styled.img`
  width: 70px;
  padding: 10px;
`;

const StyledBold = styled.span`
  font-size: 22px;
`;

// eslint-disable-next-line arrow-body-style
const GlobalWrapper = () => {
  return (
    <Container
      fluid
      style={{
        backgroundColor: '#888',
      }}
    >
      <Row>
        <Col
          xs={12}
          style={{
            padding: 0,
          }}
        >
          <Parallax
            bgImage={bg1}
            strength={500}
          >
            <div style={{
              height: '101vh',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            >
              <img
                src={require('../../assets/images/headerLogo.png')}
                alt="Logo"
                style={{
                  height: '95%',
                  maxHeight: 400,
                }}
              />
            </div>
          </Parallax>
        </Col>
        <Col xs={12}>
          <Row style={{
            backgroundColor: '#fff',
            paddingTop: 100,
            paddingBottom: 100,
          }}
          >
            <HalfCol>
              <Slide bottom>
                <img
                  src={require('../../assets/images/screenShots.png')}
                  alt="Screenshots"
                  style={{
                    width: '100%',
                    maxWidth: 300,
                  }}
                />
              </Slide>
            </HalfCol>
            <HalfCol>
              <Slide right>
                <div>
                  <StyledParagraph
                    noPaddingBottom
                  >
                    Triboo est une méta-plateforme collaborative qui facilite les connections intra-professionnelles.
                    Elle permet à des personnes d’une même profession de dégager une plus-value issue de la
                    collaboration et du partage.
                  </StyledParagraph>
                  <StyledParagraph
                    noPaddingBottom
                  >
                    En pratique, cette plus-value se traduit par l’émergence de nouveaux outils informatiques qui ne
                    pourraient pas voir le jour autrement : système d’échange de documents ou de bonnes pratiques,
                    services d’entraide, de remplacement, d’aide aux stagiaires, de recherche de personnel, d’alertes ou
                    d’urgences spécifiques au domaine d’activité, etc.
                  </StyledParagraph>
                  <StyledParagraph
                    noPaddingBottom
                  >
                    Chaque profession prise en charge par Triboo dispose de sa plateforme dédiée et spécialisée. A
                    chaque métier ses finesses et son identité. A chaque métier sa Triboo.
                  </StyledParagraph>
                  <StyledParagraph>
                    Les fonctionnalités proposées sont développées en collaboration avec les utilisateurs. Nous
                    consacrons beaucoup de temps à les rencontrer et à comprendre les enjeux réels et actuels de leur
                    métier. Le haut degré de spécialisation qui en résulte distingue Triboo des simples plateformes
                    sociales génériques et inadaptées. Chez Triboo, le réseau social n’est pas une fin en soi. Il n’est
                    que le ciment permettant de bâtir des outils professionnels de nouvelle génération.
                  </StyledParagraph>
                </div>
              </Slide>
            </HalfCol>
          </Row>
        </Col>
      </Row>

      {/* <SizedSection */}
      {/*  headerImageXs={bg3xs} */}
      {/*  headerImageXl={bg3xl} */}
      {/*  headerTitle="Notre produit" */}
      {/* > */}
      {/*  <HalfCol> */}
      {/*    <Slide bottom> */}
      {/*      <div> */}
      {/*        <StyledParagraph noPaddingBottom> */}
      {/*          Bien que nous nous voulions spécifiques à chaque corps de métier, il est indéniable que certaines */}
      {/*          fonctionnalités peuvent profiter à tous, indépendamment de son métier et être extraite vers un tronc */}
      {/*          commun: */}
      {/*        </StyledParagraph> */}
      {/*        <StyledParagraph noPaddingBottom> */}
      {/*          - Une messagerie privée ; */}
      {/*        </StyledParagraph> */}
      {/*        <StyledParagraph noPaddingBottom noPaddingTop> */}
      {/*          - Une messagerie de groupe ; */}
      {/*        </StyledParagraph> */}
      {/*        <StyledParagraph noPaddingBottom noPaddingTop> */}
      {/*          - Un système de partage de contenu ; */}
      {/*        </StyledParagraph> */}
      {/*        <StyledParagraph noPaddingBottom noPaddingTop> */}
      {/*          - Un module d’organisation d’évènement ; */}
      {/*        </StyledParagraph> */}

      {/*        <StyledParagraph noPaddingBottom noPaddingTop> */}
      {/*          - Un ensemble de forums ; */}
      {/*        </StyledParagraph> */}
      {/*        <StyledParagraph noPaddingBottom noPaddingTop> */}
      {/*          - ... */}
      {/*        </StyledParagraph> */}
      {/*      </div> */}
      {/*    </Slide> */}
      {/*  </HalfCol> */}
      {/*  <HalfCol> */}
      {/*    <Slide right> */}
      {/*      <div> */}
      {/*        <StyledParagraph noPaddingBottom> */}
      {/*          Les autres fonctionnalités inhérentes à chaque plateformes sont directement liées à la profession */}
      {/*          ciblée. */}
      {/*        </StyledParagraph> */}
      {/*        <StyledParagraph> */}
      {/*          Nous prenons du temps pour rencontrer et partager avec différents acteurs de celle-ci et comprendre les */}
      {/*          enjeux actuels de leur métier. Il existe pour chaque profession une multitude de spécificité, rendant */}
      {/*          les modèles actuels de plateformes sociales trop génériques. Notre boulot consiste à détecter ces */}
      {/*          spécificités, à les comprendre et à en dériver des modules pour la plateforme. Cette méthode de travail, */}
      {/*          en partenariat avec des professionnels, nous permet d’acquérir une expertise réel sur les besoins de la */}
      {/*          profession et de proposer une plateforme adaptée au métier. */}
      {/*        </StyledParagraph> */}
      {/*      </div> */}
      {/*    </Slide> */}
      {/*  </HalfCol> */}
      {/* </SizedSection> */}

      {/* <SizedSection */}
      {/*  headerImageXs={bg2xs} */}
      {/*  headerImageXl={bg2xl} */}
      {/*  headerTitle="La roadmap" */}
      {/* > */}
      {/*  <HalfCol> */}
      {/*    <Slide bottom> */}
      {/*      <img */}
      {/*        src={require('../../assets/images/timesheet.png')} */}
      {/*        alt="Timesheet" */}
      {/*        style={{ */}
      {/*          width: '100%', */}
      {/*        }} */}
      {/*      /> */}
      {/*    </Slide> */}
      {/*  </HalfCol> */}
      {/*  <HalfCol> */}
      {/*    <Slide right> */}
      {/*      <StyledParagraph> */}
      {/*        A l’heure actuelle, notre équipe se voue entièrement au projet. Le travail avance rapidement et notre */}
      {/*        motivation est inébranlable mais nous sommes conscient qu’il faudra du temps avant qu’une première version */}
      {/*        soit en production. */}
      {/*      </StyledParagraph> */}
      {/*    </Slide> */}
      {/*  </HalfCol> */}
      {/* </SizedSection> */}

      <SizedSection
        headerImageXs={bg4xs}
        headerImageXl={bg4xl}
        headerTitle="Investisseurs"
      >
        <FullCol>
          <Slide bottom>
            <div>
              <StyledParagraph
                noPaddingBottom
              >
                Nous sommes constamment à la recherche de nouveaux partenaires pouvant nous aider à accélérer le
                développement de la méta-plateforme. Soyez assurés que nous étudions sérieusement toutes vos
                propositions.
              </StyledParagraph>
              <StyledParagraph>
                N’hésitez pas à nous contacter si vous souhaitez vous associer à une équipe dynamique et à un produit
                innovant, à haute valeur ajoutée.
              </StyledParagraph>
            </div>
          </Slide>
        </FullCol>
      </SizedSection>

      <SizedSection
        headerImageXs={bg5xs}
        headerImageXl={bg5xl}
        headerTitle="L'équipe"
      >
        <Col
          xs={12}
          md={6}
          lg={4}
          style={{
            padding: 20,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-start',
          }}
        >
          <Slide bottom>
            <img
              src={require('../../assets/images/matt.jpg')}
              alt="Matthias"
              style={{
                maxWidth: 200,
                borderRadius: 200,
              }}
            />
            <StyledParagraph
              noPaddingBottom
            >
              <StyledBold
                style={{
                  fontSize: 26,
                }}
              >
                Matthias Vannuffel
              </StyledBold>
            </StyledParagraph>
            <StyledParagraph
              noPaddingBottom
              noPaddingTop
            >
              co-fondateur & développeur
            </StyledParagraph>
            <a
              href="https://www.linkedin.com/in/matthias-vannuffel-async/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <StyledSocialImage
                src={require('../../assets/images/linkedinBlue.png')}
                alt="facebook"
              />
            </a>
          </Slide>
        </Col>

        <Col
          xs={12}
          md={6}
          lg={4}
          style={{
            padding: 20,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-start',
          }}
        >
          <Slide left>
            <img
              src={require('../../assets/images/ben.jpg')}
              alt="Benoit"
              style={{
                maxWidth: 200,
                borderRadius: 200,
              }}
            />
            <StyledParagraph
              noPaddingBottom
            >
              <StyledBold
                style={{
                  fontSize: 26,
                }}
              >
                Benoit Antoine
              </StyledBold>
            </StyledParagraph>
            <StyledParagraph
              noPaddingBottom
              noPaddingTop
            >
              co-fondateur & développeur
            </StyledParagraph>
            <a
              href="https://www.linkedin.com/in/benoit-antoine-async/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <StyledSocialImage
                src={require('../../assets/images/linkedinBlue.png')}
                alt="facebook"
              />
            </a>
          </Slide>
        </Col>

        <Col
          xs={12}
          md={{
            span: 6,
            offset: 3,
          }}
          lg={{
            span: 4,
            offset: 0,
          }}
          style={{
            padding: 20,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-start',
          }}
        >
          <Slide right>
            <img
              src={require('../../assets/images/clem.jpg')}
              alt="Clément"
              style={{
                maxWidth: 200,
                borderRadius: 200,
              }}
            />
            <StyledParagraph
              noPaddingBottom
            >
              <StyledBold
                style={{
                  fontSize: 26,
                }}
              >
                Clément Coclet
              </StyledBold>
            </StyledParagraph>
            <StyledParagraph
              noPaddingBottom
              noPaddingTop
            >
              co-fondateur & développeur
            </StyledParagraph>
            <a
              href="https://www.linkedin.com/in/clement-coclet-async/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <StyledSocialImage
                src={require('../../assets/images/linkedinBlue.png')}
                alt="facebook"
              />
            </a>
          </Slide>
        </Col>
      </SizedSection>

      <Row>
        <Col
          xs={12}
          style={{
            padding: 0,
          }}
        >
          <Parallax
            bgImage={bg1}
            strength={500}
          >
            <div style={{
              minHeight: '50vh',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
            >
              <StyledParagraph
                style={{
                  color: '#fff',
                  fontSize: 28,
                }}
              >
                email : hello@async.be
              </StyledParagraph>
              <div>
                <a
                  href="https://www.linkedin.com/company/triboo-be/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <StyledSocialImage
                    src={require('../../assets/images/linkedin.png')}
                    alt="linkedin"
                  />
                </a>

                <a
                  href="https://www.facebook.com/triboo.be/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <StyledSocialImage
                    src={require('../../assets/images/facebook.png')}
                    alt="facebook"
                  />
                </a>

                <a
                  href="https://twitter.com/TribooBe/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <StyledSocialImage
                    src={require('../../assets/images/twitter.png')}
                    alt="twitter"
                  />
                </a>

                <a
                  href="https://www.instagram.com/triboo_be/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <StyledSocialImage
                    src={require('../../assets/images/instagram.png')}
                    alt="instagram"
                  />
                </a>
              </div>
            </div>
          </Parallax>
        </Col>
      </Row>
    </Container>
  );
};

export default GlobalWrapper;
