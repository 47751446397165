import React from 'react';
import PropTypes from 'prop-types';
import { Parallax } from 'react-parallax';
import { Col, Row } from 'react-bootstrap';

const Section = ({ headerImage, headerTitle, children }) => (
  <Row>
    <Col
      xs={12}
      style={{
        padding: 0,
      }}
    >
      <Parallax
        bgImage={
          headerImage
        }
        strength={1080}
      >
        <div style={{
          height: '50vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        >
          <h1 style={{
            color: '#fff',
            fontSize: 46,
            fontWeight: 'bold'
          }}
          >
            {headerTitle}
          </h1>
        </div>
      </Parallax>
    </Col>
    <Col xs={12}>
      <Row style={{
        backgroundColor: '#fff',
        paddingTop: 100,
        paddingBottom: 100,
      }}
      >
        {children}
      </Row>
    </Col>
  </Row>
);

Section.propTypes = {
  headerImage: PropTypes.string.isRequired,
  headerTitle: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

const SizedSection = ({ headerImageXs, headerImageXl, headerTitle, children }) => {
  return (
    <>
      <Row>
        <Col xs={12} className="d-block d-md-none">
          <Section
            headerImage={headerImageXs}
            headerTitle={headerTitle}
          >
            {children}
          </Section>
        </Col>
      </Row>

      <Row>
        <Col xs={12} className="d-none d-md-block">
          <Section
            headerImage={headerImageXl}
            headerTitle={headerTitle}
          >
            {children}
          </Section>
        </Col>
      </Row>
    </>
  );
};

SizedSection.propTypes = {
  headerImageXs: PropTypes.string.isRequired,
  headerImageXl: PropTypes.string.isRequired,
  headerTitle: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default SizedSection;
